import { debounce } from 'lodash-es';

const HEADER_SEARCH_ELEMENT_CLASS = 'searchContainer';
const STICKY_ELEMENT_CLASS = `${HEADER_SEARCH_ELEMENT_CLASS}--sticky`;
const SLIDE_ANIMATION_CLASS = `${HEADER_SEARCH_ELEMENT_CLASS}--noAnimation`;

let debouncedResizeHandler: (event: Event) => void;

function stickyClassHandler (element:HTMLElement, threshold:number, offset:number) {

	const headerSearchInput = document.getElementById('search');
	const isSticky = element.classList.contains(STICKY_ELEMENT_CLASS);

	if (window.scrollY > threshold) {

		if (!isSticky) {

			const nonStickyHeaderSearchWidth = headerSearchInput.offsetWidth;

			// Recalculate Search box width when window is resized or rotated
			debouncedResizeHandler = debounce(() => {

				// Remove animation to avoid flicker
				element.classList.add(SLIDE_ANIMATION_CLASS);

				// Remove sticky to get computed width of Search box in its original position in the header
				element.classList.remove(STICKY_ELEMENT_CLASS);

				headerSearchInput.style.width = '';
				headerSearchInput.style.width = `${headerSearchInput.offsetWidth}px`;

				element.classList.add(STICKY_ELEMENT_CLASS);
				element.style.setProperty('--search-input-offset-left', `${headerSearchInput.offsetLeft}px`);

			}, 10);

			window.addEventListener('resize', debouncedResizeHandler);

			element.classList.add(STICKY_ELEMENT_CLASS);

			// Set sticky Search width the same as when it's not sticky
			headerSearchInput.style.width = `${nonStickyHeaderSearchWidth}px`;

			element.style.setProperty('--search-input-offset-left', `${headerSearchInput.offsetLeft}px`);
			document.body.style.marginTop = `${offset}px`;

		}

	} else if (isSticky) {

		element.classList.remove(STICKY_ELEMENT_CLASS);
		element.style.setProperty('--search-input-offset-left', '0px'); // needs unit for calc()
		headerSearchInput.style.width = '';
		element.classList.remove(SLIDE_ANIMATION_CLASS);
		window.removeEventListener('resize', debouncedResizeHandler);

		document.body.style.marginTop = '0';

	}

}

/**
 * Initializes the Sticky Header Search Bar.
 * Use this function to make the Header Search sticky on any page.
 * @returns {void}
 */
function initStickyHeaderSearch () {

	const element = document.querySelector(`.${HEADER_SEARCH_ELEMENT_CLASS}`);
	if (!element || !(element instanceof HTMLElement)) {

		return;

	}

	window.setTimeout(() => {

		const bodyMargin = element.offsetHeight;
		const heightBuffer = bodyMargin * 4;
		const offsetY = element.offsetTop + heightBuffer;

		stickyClassHandler(element, offsetY, bodyMargin);

		window.addEventListener(
			'scroll',
			debounce(() => {

				stickyClassHandler(element, offsetY, bodyMargin);

			}, 10)
		);

	}, 1000);

}

export default initStickyHeaderSearch;
